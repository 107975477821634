import * as yup from "yup";
import { vehicle_no } from "../../../../../../utils/regularExpressions";

function checkAlphanumeric(str) {
  const atleastOneNumber = /\d/;
  return atleastOneNumber.test(str);
}
const vehicleDetailsSchema = yup.object().shape({
  registration_number: yup.string().when("is_registered", {
    is: "Y",
    then: yup.string().required("Registration number is required"),
    otherwise: yup.string().notRequired(),
  }),
  is_financed: yup.string().required(),
  prev_insurer_val: yup.string().when("$product_code", {
    is: (val) => val !== "New Business",
    then: yup.string().required("Previous insurer name is required"),
    otherwise: yup.string().notRequired(),
  }),
  prev_insurer_name: yup.string().when("$product_code", {
    is: (val) => val !== "New Business",
    then: yup.string().required("Previous insurer name is required"),
    otherwise: yup.string().notRequired(),
  }),
  prev_policy_no: yup.string().when("$product_code", {
    is: (val) => val !== "New Business",
    then: yup.string().required("Previous Policy Number is required"),
    otherwise: yup.string().notRequired(),
  }),
  engine_number: yup
    .string()
    .required("Engine number is required")
    .min(6, "Please enter a valid engine number")
    .max(17, "Please enter a valid engine number")
    .test(
      "engine_number",
      "Please enter a valid engine number",
      checkAlphanumeric
    ),
  chassis_number: yup
    .string()
    .required("Chassis number is required")
    .min(6, "Please enter a valid chassis number")
    .max(25, "Please enter a valid chassis number")
    .test(
      "chassis_number",
      "Please enter a valid chassis number",
      checkAlphanumeric
    ),
  financier_val: yup.string().when("is_financed", {
    is: "Y",
    then: yup.string().required("Financier name is required"),
  }),
  financier_name: yup.string().when("is_financed", {
    is: "Y",
    then: yup.string().required("Financier name is required"),
  }),
  isOD: yup.boolean(),
  tp_prev_insurer_val: yup.string().when("isOD", {
    is: (val) => val === true,
    then: yup.string().required("Insurer name is required"),
  }),
  tp_prev_insurer_name: yup.string().when("isOD", {
    is: (val) => val === true,
    then: yup.string().required("Insurer name is required"),
  }),
  tp_prev_policy_no: yup.string().when("isOD", {
    is: (val) => val === true,
    then: yup.string().required("TP Policy Number is required"),
  }),
  tp_existing_exp_date: yup.string().when("isOD", {
    is: (val) => val === true,
    then: yup.string().required("Existing TP policy expiry date is required"),
  }),
});
export default vehicleDetailsSchema;
