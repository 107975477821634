import axios from "axios";

const api = axios.create({
  baseURL: "https://api.vkover.com/",
  headers: {
    "Content-Type": "application/json",
  },
});
// api.interceptors.request.use((req) => {
//   const urlQueryStrings = new URLSearchParams(window.location.search);
//   const QuoteId = urlQueryStrings.get("quote_id");
//   if (QuoteId) {
//     req.headers.common["Quote-Id"] = QuoteId;
//   }
//   return req;// });
export default api;
