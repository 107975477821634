import React from "react";
import {
  BackButton,
  FormLabel,
  InputWrapper,
  Question,
  RegistrationWrapper,
  Text,
} from "../NoCarNumber.style";
import "styled-components/macro";
import { Button } from "../../../../../components/Button/Button";
import AutoCompleteSelect from "../../../../../components/AutoCompleteSelect/AutoComplete";
import { useRTOQuery } from "../../../../../services/CarServices";
import getLastNYears from "../../../../../utils/getLastFifteenYears";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  noNumberSelector,
  setRtoDetails,
  setRegistrationYear,
} from "../../../Landing.slice";
import { useState } from "react";
const Registration = ({ setActive, active, onSave }) => {
  const { isLoading, rtoList } = useRTOQuery();
  const dispatch = useDispatch();
  const { rto_id, registration_year, rto_name } = useSelector(noNumberSelector);
  const [errors, setErrors] = useState({
    registration_year: "",
    rto_name: "",
  });
  return (
    <>
      <FormLabel>
        <BackButton
          onClick={() => {
            setActive(active - 1);
          }}
        >
          <i class="fas fa-long-arrow-alt-left"></i>
        </BackButton>
        <Text>Enter car registration year ?</Text>
      </FormLabel>
      <Question>
        Registration <span>Place</span>
      </Question>
      <RegistrationWrapper>
        <InputWrapper>
          <AutoCompleteSelect
            size="large"
            label="Registration Place"
            options={rtoList}
            loading={isLoading}
            _key={"label"}
            error={errors?.rto_name}
            selectedValue={rto_id && { id: rto_id, label: rto_name }}
            handleChange={(value) => {
              dispatch(setRtoDetails(value));
              setErrors((prev) => ({ ...prev, rto_name: "" }));
            }}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => option.label === value.label}
          ></AutoCompleteSelect>
        </InputWrapper>
      </RegistrationWrapper>
      <Question>
        Registration <span>year</span>
      </Question>
      <RegistrationWrapper>
        <InputWrapper>
          <AutoCompleteSelect
            size="large"
            label="Registration Year"
            error={errors?.registration_year}
            selectedValue={registration_year}
            handleChange={(value) => {
              dispatch(setRegistrationYear(value));
              setErrors((prev) => ({ ...prev, registration_year: "" }));
            }}
            options={getLastNYears(15)}
          ></AutoCompleteSelect>
        </InputWrapper>
      </RegistrationWrapper>
      <RegistrationWrapper>
        <Button
          primary
          large
          onClick={() => {
            let errors = {};
            let isValid = true;
            if (!rto_id) {
              errors = {
                ...errors,
                rto_name: "Registration place is required",
              };
              isValid = false;
            }
            if (!registration_year) {
              errors = {
                ...errors,
                registration_year: "Registration year is required",
              };
              isValid = false;
            }
            if (isValid) onSave();
            else setErrors(errors);
          }}
          css={`
            width: 60%;
            margin: 24px auto 0;
            @media (max-width: 576px) {
              width: 100%;
            }
          `}
        >
          Save and Continue
        </Button>
      </RegistrationWrapper>
    </>
  );
};

export default Registration;
