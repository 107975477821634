import * as yup from "yup";

const validation = yup.object().shape({
  is_ckyc_no: yup.mixed().required(),
  ckyc_no: yup
    .string()
    .nullable()
    .when("is_ckyc_no", {
      is: "Y",
      then: yup.string().required("This field is requireds"),
    }),

  method_of_verification: yup
    .string()
    .nullable()
    .when(["is_ckyc_no", "product.INSURANCE_SLUG"], {
      is: (is_ckyc_no, INSURANCE) =>
        is_ckyc_no === "N" && INSURANCE === "shri_ram",
      then: yup.string().required("This field is requiredddd"),
    }),

  pan_no: yup
    .string()
    .nullable()
    .when(["is_ckyc_no", "product.INSURANCE_SLUG"], {
      is: (is_ckyc_no, INSURANCE) =>
        is_ckyc_no === "N" && INSURANCE === "shri_ram",
      then: yup
        .string()
        .required("This field is required")
        .matches(
          /^[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$|^$/,
          "Please enter a valid pan no."
        ),
    }),
  address_proof: yup.string().nullable(),
  identity_proof: yup.string().nullable(),
  address_proof_front_doc: yup.mixed().nullable(),
  address_proof_back_doc: yup.mixed().nullable(),
  identity_proof_front_doc: yup.mixed().nullable(),
  identity_proof_back_doc: yup.mixed().nullable(),
  photo_doc: yup.mixed().nullable(),

  // Add validation for Proof of Identity Type
  POI_Type: yup
    .string()
    .nullable()
    .when(["is_ckyc_no", "product.INSURANCE_SLUG"], {
      is: (is_ckyc_no, INSURANCE) =>
        is_ckyc_no === "N" && INSURANCE === "shri_ram",
      then: yup.string().required("Proof of Identity Type is required"),
    }),

  // Add validation for Proof of Address Type
  POA_Type: yup
    .string()
    .nullable()
    .when(["is_ckyc_no", "product.INSURANCE_SLUG"], {
      is: (is_ckyc_no, INSURANCE) =>
        is_ckyc_no === "N" && INSURANCE === "shri_ram",
      then: yup.string().required("Proof of Address Type is required"),
    }),

  // Add validation for Identity Proof Number
  POI_ID: yup
    .string()
    .nullable()
    .when(["is_ckyc_no", "product.INSURANCE_SLUG"], {
      is: (is_ckyc_no, INSURANCE) =>
        is_ckyc_no === "N" && INSURANCE === "shri_ram",
      then: yup
        .string()
        .required("Identity Proof Number is required")
        .max(10, "Identity Proof Number cannot be more than 10 characters"),
    }),

  // Add validation for Proof of Address Number
  POA_ID: yup
    .string()
    .nullable()
    .when(["is_ckyc_no", "product.INSURANCE_SLUG"], {
      is: (is_ckyc_no, INSURANCE) =>
        is_ckyc_no === "N" && INSURANCE === "shri_ram",
      then: yup
        .string()
        .required("Proof of Address Number is required")
        .max(10, "Proof of Address Number cannot be more than 10 characters"),
    }),

  // Add validation for Proof of Address Document (file)
  POA_DocumentFile: yup
    .mixed()
    .nullable()
    .when(["is_ckyc_no", "product.INSURANCE_SLUG"], {
      is: (is_ckyc_no, INSURANCE) =>
        is_ckyc_no === "N" && INSURANCE === "shri_ram",
      then: yup.mixed().required("Proof of Address Document is required"),
    }),

  // Add validation for Proof of Identity Document (file)
  POI_DocumentFile: yup
    .mixed()
    .nullable()
    .when(["is_ckyc_no", "product.INSURANCE_SLUG"], {
      is: (is_ckyc_no, INSURANCE) =>
        is_ckyc_no === "N" && INSURANCE === "shri_ram",
      then: yup.mixed().required("Proof of Identity Document is required"),
    }),

  // Add validation for Photo (file)
  Insured_photo: yup
    .mixed()
    .nullable()
    .when(["is_ckyc_no", "product.INSURANCE_SLUG"], {
      is: (is_ckyc_no, INSURANCE) =>
        is_ckyc_no === "N" && INSURANCE === "shri_ram",
      then: yup.mixed().required("Photo is required"),
    }),
});

export default validation;
